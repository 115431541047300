.create-button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .create-button:hover {
    background-color: #45a049;
  }