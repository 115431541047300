.file-creator {
  text-align: center;
  margin: 50px auto;
  max-width: 400px;
}

.file-creator p {
  font-size: 1.2em;
}

.template-dropdown {
  margin-bottom: 10px;
}

.dark-mode .template-dropdown {
  color: #000000;
}

.template-dropdown label {
  margin-right: 10px;
}

.template-dropdown select {
  padding: 10px;
  font-size: 16px;
}

.create-button {
  margin-top: 20px;
}

.Button2 {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
}

.Button2:hover {
  background-color: #2980b9;
}